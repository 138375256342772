import React from 'react'
import { FormattedMessage } from 'react-intl'
import {
  Wrapper,
  Flex,
  BenefitWrapper
} from './styles'
import BenefitImg1 from 'media/images/affiliate/what-are-the-benefits/benefit-1.svg'
import BenefitImg2 from 'media/images/affiliate/what-are-the-benefits/benefit-2.svg'
import BenefitImg3 from 'media/images/affiliate/what-are-the-benefits/benefit-3.svg'
import DottedImg from 'media/images/affiliate/what-are-the-benefits/dotted-bg.svg'

const BENEFITS = [
  {
    icon  : BenefitImg1,
    title : <FormattedMessage id='9babe6a17' />,
    desc  : <FormattedMessage id='f74e0a1c9' />
  },
  {
    icon  : BenefitImg2,
    title : <FormattedMessage id='4392407fc' />,
    desc  : <FormattedMessage id='a45927de6' />
  },
  {
    icon  : BenefitImg3,
    title : <FormattedMessage id='52ded2319' />,
    desc  : <FormattedMessage id='91b4bfcfc' />
  }
]

const WhatAreTheBenefits = () => (
  <Wrapper>
    <h1 id='what-are-the-benefits'>
      <a href='#what-are-the-benefits' aria-label='highest permalink'>
        <FormattedMessage id='efb381749' />
      </a>
    </h1>
    <BenefitWrapper>
      {
        BENEFITS.map((benefit) => (
          <Flex key={ benefit.title }>
            <div>
              <img src={ benefit.icon } />
            </div>
            <img src={ DottedImg } />
            <div>
              <h2>
                { benefit.title }
              </h2>
              <p>
                { benefit.desc }
              </p>
            </div>
          </Flex>
        ))
      }
    </BenefitWrapper>
  </Wrapper>
)

export default WhatAreTheBenefits
