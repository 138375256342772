import React from 'react'
import { FormattedMessage } from 'utils/intl'
import {
  Wrapper, TitleWrapper
} from './styles'
import icon from 'media/images/affiliate/what-are-qlue/icon.svg'
import { Link } from 'gatsby'

const WhatAreQlueUnityAffiliates = () => (
  <Wrapper>
    <TitleWrapper>
      <h1 id='what-are-qlueunity-affiliates'>
        <Link to="#what-are-qlueunity-affiliates">
          <div><FormattedMessage id="2d3a7a0dd" /></div><div><FormattedMessage id="d8d32a5ab" /></div>
        </Link>
      </h1>
      <img src={ icon } />
    </TitleWrapper>
    <p>
      <FormattedMessage id='b2aeee387' />
    </p>
  </Wrapper>
)

export default WhatAreQlueUnityAffiliates
