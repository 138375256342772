import styled, { css } from 'styled-components'
import { darkBlueGrey } from 'components/baseColor'
import { IS_FRAME_MOBILE, IS_FRAME_TABLET } from 'utils'

export const paddingPage = css`
  padding: 6em 5em 0;

  ${IS_FRAME_MOBILE} {
    padding: 1rem 0;
  }
  ${IS_FRAME_TABLET} {
    padding: 1rem 0;
  }
`

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 0 0 40%;
  font-weight: 800;

  h1 {
    margin: 0.67em 1em;
    a {
      text-decoration: none;
      color: ${darkBlueGrey};

      div:nth-child(1) {
        font-size: 24px;
        font-weight: normal;
      }
      div:nth-child(2) {
        font-size: 36px;
        font-weight: 600;
      }
    }
  }

  img {
    width: auto;
    height: 7rem;
    margin-left: auto;
  }

  ${IS_FRAME_MOBILE} {
    display: flex;
    align-items: center;
    align-self: normal;

    h1 {
      margin: 0;
      margin-right: 2rem;
      a {
        div:nth-child(1) {
          font-size: 1.125rem;
          font-weight: normal;
        }
        div:nth-child(2) {
          font-size: 1.5rem;
          font-weight: 600;
        }
      }
    }

    p {
      padding: 1rem;
      text-align: left;
    }

    img {
      width: auto;
      height: 4rem;
      margin-left: auto;
    }
  }

  ${IS_FRAME_TABLET} {
    display: flex;
    align-items: center;
    align-self: flex-start;

    h1 {
      margin: 0;
      margin-right: 2rem;
      a {
        div:nth-child(1) {
          font-size: 1.125rem;
          font-weight: normal;
        }
        div:nth-child(2) {
          font-size: 1.5rem;
          font-weight: 600;
        }
      }
    }

    p {
      padding: 1rem;
      text-align: left;
    }

    img {
      width: auto;
      height: 4rem;
      margin-left: auto;
    }
  }
`

export const Wrapper = styled.div`
  ${paddingPage}

  display: flex;
  background-image: linear-gradient(181deg, #c7f5ff, white);
  position: relative;
  align-items: center;
  padding: 2rem;

  > img {
    position: relative;
    margin: -35px 80px 0 82px;
    width: 10%;
    height: 150px;
  }

  > h1 {
    font-size: 36px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: ${darkBlueGrey};
    margin-top: 0px;
    margin-bottom: 0;

    div {
      width: 100%;
      display: flex;
      flex-direction: column;
      a :nth-child(1) {
        font-size: 24px;
        left: 0;
        color: ${darkBlueGrey};
        text-decoration: none;
      }
      a {
        text-decoration: none;
        color: ${darkBlueGrey};
      }
    }
  }

  p {
    width: 50%;
    font-size: 1rem;
    opacity: 0.7;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.1px;
    text-align: left;
    color: ${darkBlueGrey};
    padding: 1rem;
    flex: 0 0 55%;
    padding-left: 4rem;
  }

  ${IS_FRAME_MOBILE} {
    flex-direction: column;
    padding: 1rem;
    > h1 {
      font-size: 2em;
      margin-left: auto;
      margin-right: auto;
    }

    > p {
      width: auto;
      margin: 0;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: 0.1px;
      padding-left: 0;
      color: ${darkBlueGrey};
    }
  }

  ${IS_FRAME_TABLET} {
    flex-direction: column;
    padding: 1rem;
    > h1 {
      font-size: 2em;
      margin-left: auto;
      margin-right: auto;
    }

    > p {
      width: auto;
      margin: 0;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: 0.1px;
      padding-left: 0;
    }
  }
`
