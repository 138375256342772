import styled, { css } from 'styled-components'
import { darkBlueGrey } from 'components/baseColor'
import { IS_FRAME_MOBILE, IS_FRAME_TABLET } from 'utils'

export const paddingPage = css`
  padding: 0 5em;

  ${IS_FRAME_MOBILE} {
    padding: 1rem 0;
  }
  ${IS_FRAME_TABLET} {
    padding: 1rem 0;
  }
`

export const Wrapper = styled.div`
  ${paddingPage}
  margin-top: 7rem;

  ${IS_FRAME_MOBILE} {
    margin-top: 0;
  }
  ${IS_FRAME_TABLET} {
    margin-top: 0;
  }

  a {
    color: ${darkBlueGrey};
    text-decoration: none;
  }

  > h1 {
    margin-bottom: 3rem;
    font-size: 4.167em;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: ${darkBlueGrey};
    text-align: center;

    ${IS_FRAME_MOBILE} {
      font-size: 2em;
      margin: 0 1em 1em 1em;
    }
    ${IS_FRAME_TABLET} {
      font-size: 1.5rem;
      margin: 0 1em 1em 1em;
    }
  }

`

export const WrapperItems = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(10em, 1fr));
  grid-column-gap: 5em;
  grid-auto-rows: minmax(min-content, max-content);

  ${IS_FRAME_MOBILE} {
    padding: 0 2em;
    display: flex;
    flex-direction: column;
  }
  ${IS_FRAME_TABLET} {
    padding: 0 2em;
    display: flex;
    flex-direction: column;
  }
`

export const Item = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  text-decoration: none;
  border-radius: 1.25rem;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

  > img {
    width: 50%;
  }


  ${IS_FRAME_MOBILE} {
    margin-bottom: 3em;
    border-radius: 0.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ${IS_FRAME_TABLET} {
    margin-bottom: 3em;
    border-radius: 0.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
`

export const ItemTitle = styled.p`
  font-size: 1.6em;
  width: 90%;
  align-self: flex-start;
  text-align: left;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: ${darkBlueGrey};
  margin-top: 2em;
  margin-bottom: 0em;

  ${IS_FRAME_MOBILE} {
    font-size: 1.4em;
  }
  ${IS_FRAME_TABLET} {
    font-size: 1.125rem;
  }
`

export const ItemDesc = styled.p`
  opacity: 0.9;
  font-size: 1.1em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.69;
  letter-spacing: 0.1px;
  color: ${darkBlueGrey};

  ${IS_FRAME_MOBILE} {
    font-size: 0.9rem;
    line-height: 1.79;
    opacity: 0.5;
  }

  ${IS_FRAME_TABLET} {
    font-size: 0.9rem;
    line-height: 1.79;
    opacity: 0.5;
  }
`

export const ItemDetailsText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.2em;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  color: ${darkBlueGrey};
  align-self: flex-end;
  margin-top: auto;

  > svg path {
    fill: ${darkBlueGrey};
  }
`
