import { darkBlueGrey } from 'components/baseColor'
import styled, { css } from 'styled-components'
import { IS_FRAME_MOBILE, IS_FRAME_TABLET } from 'utils'

const paddingPage = css`
  padding: 0 9em 0;

  ${IS_FRAME_MOBILE} {
    padding: 0 0 0;
  }

  ${IS_FRAME_TABLET} {
    padding: 0 2em 0;
  }
`

export const Wrapper = styled.div`
  ${paddingPage}

  > h1 {
    margin-bottom: 3rem;
    font-size: 4.167em;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    color: ${darkBlueGrey};
    position: relative;
    a {
      color: ${darkBlueGrey};
      text-decoration: none;
    }
  }

  ${IS_FRAME_MOBILE} {

    > h1 {
      font-size: 2em;
      margin-left: auto;
      margin-right: auto;
      padding: 0;
      margin-bottom: 1em;
      a {
        color: ${darkBlueGrey};
        text-decoration: none;
      }
    }
  }

  ${IS_FRAME_TABLET} {
    margin-top: 2rem;

    > h1 {
      font-size: 1.5rem;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 1.5rem;
      padding: 0;
      a {
        color: ${darkBlueGrey};
        text-decoration: none;
      }
    }
  }
`

export const BenefitWrapper = styled.div`
  div:nth-child(2) {
    > img {
      top: -2rem;
      position: relative;
      left: calc(100vw - 42rem);
      z-index: -1;
    }
    div:nth-child(3) {
      margin-left: -6rem;
      margin-right: 5rem;
    }
  }

  @media only screen and (min-width: 1600px) {
    div:nth-child(2) {
      > img {
        left: 58rem;
      }
    }
  }

  ${IS_FRAME_MOBILE} {
    div :nth-child(2) {
      div:last-child {
        margin: 0.5rem 1.6rem;
      }
    }
  }

  ${IS_FRAME_TABLET} {
    div :nth-child(2) {
      div:last-child {
        margin: 3.5rem 1.6rem;
      }
    }
  }
`

export const Flex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5rem;

  > div {
    h2 {
      color: ${darkBlueGrey};
      font-weight: 600;
    }
    p {
      color: ${darkBlueGrey};
      font-weight: normal;
      font-size: 18px;
      letter-spacing: 0.1px;
      text-align: left;
      opacity: 0.5;
    }
    &:nth-child(3) {
      padding: 1rem 1.5rem;
      background-color: #f8feff;
      border-radius: 35px;
      margin-top: 4rem;
    }

    + img {
      top: -2rem;
      position: relative;
      left: 6rem;
      z-index: -1;
    }

    ${IS_FRAME_TABLET} {
      + img {
        display: none;
      }
    }
  }

  ${IS_FRAME_MOBILE} {
    flex-direction: column;
    margin-bottom: 0;

    > div {
      img {
        width: auto;
        height: 11.25rem;
        margin: 0;
      }

      h2 {
        font-size: 18px;
      }
      p {
        font-size: 14px;
      }
      &:nth-child(3) {
        padding: 1rem 1rem;
      }

      + img {
        display: none;
      }
    }

    > div {
      &:nth-child(3) {
        margin: 0.5rem 1.6rem;
      }
    }
  }

  ${IS_FRAME_TABLET} {
    flex-direction: column;
    margin-bottom: 0;

    > div {
      img {
        width: auto;
        height: 11.25rem;
        margin: 0;
      }

      h2 {
        font-size: 18px;
      }
      p {
        font-size: 14px;
      }

      + img {
        display: none;
      }
    }

    > div {
      &:nth-child(3) {
        margin: 0.5rem 1.6rem;
      }
    }
  }
`
