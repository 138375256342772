import styled from 'styled-components'
import { IS_FRAME_MOBILE, IS_FRAME_TABLET } from 'utils'

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  background-image: linear-gradient(to bottom, #1160fb, #0895f7, rgba(0, 200, 244, 0.8));
  height: 23rem;
  padding: 3rem;

  ${IS_FRAME_MOBILE} {
    flex-direction: column;
    height: auto;
    align-items: center;
    padding: 0;
    justify-content: unset;
  }
  ${IS_FRAME_TABLET} {
    flex-direction: column;
    height: auto;
    align-items: center;
    padding: 0;
    justify-content: unset;
  }
`

export const ImageContainer = styled.div`
  > img {
    height: auto;
    width: 33rem;
    margin-top: 2rem;
  }

  ${IS_FRAME_MOBILE} {
    padding: 1rem;
    text-align: center;

    > img {
      width: 80%;
      height: auto;
      margin-top: 0;
    }
  }
  ${IS_FRAME_TABLET} {
    margin-bottom: 2rem;
  }
`

export const CaptionWrapper = styled.div`
  width: 40%;

  > h2 {
    color: #fff;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.28;
    letter-spacing: normal;
    text-align: left;
    font-size: 36px;
    margin: 0 0 0;
  }

  > p {
    color: #fff;
    line-height: 1.67;
    letter-spacing: 0.1px;
    font-weight: normal;
    margin: 20px 0 20px;
    font-size: 18px;
    letter-spacing: 0.1px;
    text-align: left;
    flex-grow: 0;
    opacity: 0.8;
  }

  .join-us__btn {
    font-size: 1.3em;
    padding: 0.5rem 2rem;
    background-color: #f9c846;
    top: 30px;
    width: fit-content;
  }

  ${IS_FRAME_MOBILE} {
    text-align: center;
    width: unset;
    padding: 0 2em;
    top: 0%;
    left: 0%;

    > h2 {
      font-size: 1.75rem;
      text-align: center;
      font-weight: unset;
      font-weight: 600;
      line-height: 1.42;
      margin: 0.25rem 0;
    }

    > p {
      font-size: 0.9rem;
      line-height: 1.5;
      letter-spacing: 0.1;
      font-weight: unset;
      text-align: center;
    }

    .join-us__btn {
      font-size: 1.2em;
      padding: 0.5em 1em;
      width: max-content
      height: auto;
      padding: 0.5rem 0.75rem;
    }

    img {
      width: 85%;
      height: auto;
      margin-top: 1.75rem;
      max-width: 25rem;
    }
  }

  ${IS_FRAME_TABLET} {
    text-align: center;
    width: unset;
    padding: 0 2em;
    top: 0%;
    left: 0%;

    > h2 {
      font-size: 1.5rem;
      text-align: center;
      font-weight: unset;
      font-weight: 600;
      line-height: 1.42;
      margin: 0.25rem 0;
    }

    > p {
      font-size: 1.125rem;
      line-height: 1.5;
      letter-spacing: 0.1;
      font-weight: normal;
      text-align: center;
      margin: 1.25rem 5rem;
      opacity: 0.8;
    }

    .join-us__btn {
      font-size: 1.2em;
      padding: 0.5em 1em;
      width: 75%;
      height: auto;
      padding: 0.5rem 0.75rem;
      margin: 0.5rem;
    }

    img {
      width: 47%;
      height: auto;
      margin-top: 2rem;
    }
  }
`
