import React from 'react'
import { Link } from 'gatsby'
import { Button } from 'components'
import {
  Wrapper,
  CaptionWrapper,
  ImageContainer
} from './styles'
import PeopleImg from 'media/images/affiliate/header/people.svg'
import { FormattedMessage } from 'utils/intl'

const Header = () => (
  <Wrapper>
    <CaptionWrapper>
      <h2>
        <FormattedMessage id='e6d997eb4' />
      </h2>
      <h2>
        <FormattedMessage id='970cf4c73' />
      </h2>
      <p>
        <FormattedMessage id='f30b70184' />
      </p>
      <Link to='/affiliate/register'>
        <Button
          type='warning'
          className='join-us__btn'
        >
          <FormattedMessage id='91694f52a' />
        </Button>
      </Link>
    </CaptionWrapper>
    <ImageContainer>
      <img src={ PeopleImg } />
    </ImageContainer>
  </Wrapper>
)

export default Header
