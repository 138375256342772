import React from 'react'
import { 
  SEO,
  Layout 
} from 'components'
import { 
  Header,
  WhatAreQlueUnityAffiliates,
  WhatAreTheBenefits,
  HowItWorks 
} from './components'
import loadable from '@loadable/component'

const HowToJoin = loadable(() => import('./components/HowToJoin'))
const BigRegisterSection = loadable(() => import('components/BigRegisterSection'))

const AffiliateView = ({ location }) => (
  <>
    <SEO
      title="Beyond Innovation, Driving Impact"
      description="Qlue provides comprehensive smart city solutions focusing on Artificial Intelligence (AI), Internet of Things (IoT), and mobile workforce technology"
    />
    <Layout location={ location }>
      <Header />
      <WhatAreQlueUnityAffiliates />
      <WhatAreTheBenefits />
      <HowItWorks />
      <HowToJoin />
      <BigRegisterSection
        caption='73a460804'
        btnText='ed34295d5'
        redirect='/affiliate/register'
      />
    </Layout>
  </>
)

export default AffiliateView
