import React from 'react'
import { FormattedMessage } from 'utils/intl'
import {
  Wrapper,
  WrapperItems,
  Item,
  ItemDesc,
  ItemTitle
} from './styles'
import Icon1 from 'media/images/affiliate/how-it-works/image-1.svg'
import Icon2 from 'media/images/affiliate/how-it-works/image-2.svg'
import Icon3 from 'media/images/affiliate/how-it-works/image-3.svg'

const ITEMS = [
  {
    title  : <FormattedMessage id='add7cc107' />,
    detail : <FormattedMessage id='6ca740e3a' />,
    icon   : Icon1
  },
  {
    title  : <FormattedMessage id='d51b4a28f' />,
    detail : <FormattedMessage id='8b8db1c15' />,
    icon   : Icon2
  },
  {
    title  : <FormattedMessage id='4d43f579b' />,
    detail : <FormattedMessage id='7bbc27e5a' />,
    icon   : Icon3
  }
]

const HowItWorks = () => (
  <Wrapper>
    <h1 id='how-it-works'>
      <a href='#how-it-works' aria-label='highest permalink'>
        <FormattedMessage id='d1691ce52' />
      </a>
    </h1>
    <WrapperItems>
      { ITEMS.map((item, i) => (
        <Item href={ `/solution/${item.href}` } key={ i }>
          <img 
            src={ item.icon }
            alt={ `icon ${item.title}` }
          />
          <ItemTitle>
            { item.title }
          </ItemTitle>
          <ItemDesc>
            { item.detail }
          </ItemDesc>
        </Item>
      )) }
    </WrapperItems>
  </Wrapper>
)

export default HowItWorks
